* {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: sans-serif, "Open Sans", Roboto, "Helvetica Neue", Helvetica;
}

a,
button,
input[type="submit"] {
    cursor: pointer;
}

#nprogress .bar {
    background: #ff5d25!important;
}


/* Fancy blur effect */

#nprogress .peg {
    box-shadow: 0 0 10px #ff5d25, 0 0 5px #ff5d25!important;
}

body.fixiPhone {
    overflow: hidden;
    position: fixed;
    width: 100%;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    select,
    textarea,
    input {
        font-size: 16px;
    }
}

.DayPicker-Day {
    outline: none;
    position: relative;
}

.DayPicker-Day--confirmadas {
    color: #00b8f5;
}

.DayPicker-Day--activas {
    color: #d10000;
}

.DayPicker-Day--disabled {
    color: #5f5f5f!important;
}

.DayPicker {
    color: #fff;
}

.rodal-mask {
    -webkit-overflow-scrolling: touch;
}

.rc-time-picker-input {
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    font-size: 16px;
    padding: 6px 10px;
    color: #000;
}

.rc-time-picker-panel-input {
    font-size: 16px;
}

.tour-backdrop {
    opacity: .3!important;
}

.tour-step-element-reflex {
    pointer-events: none!important;
}

.popover[class*="tour-"] .popover-navigation *[data-role="next"] {
    float: right!important;
}

.btn-default {
  outline: none;
  background-color: #0673f7!important;
  border-radius: 5px!important;
  color: #fff!important;
  font-size: 16px!important;
  border: 0!important;
}